<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">{{ $route.meta.title }}</span>
      </div>
      <div>
        <el-form ref="form" :model="form" label-width="120px" :rules="rules">
          <el-form-item label="banner名称：" prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入2~18字名称"
              class="input-width"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item ref="markList" label="板块：" prop="mark">
            <el-select
              v-model="form.mark"
              filterable
              placeholder="请选择板块"
              class="input-width"
              @change="changeSelect"
            >
              <el-option
                v-for="item in bannerType"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item ref="imgUrl" label="上传图片：" prop="imgUrl">
            <upload-image
              v-model="form.imgUrl"
              :upload-img="$refs.imgUrl"
              :width-size="widthSize"
              :height-size="heightSize"
              @successWH="successWH"
              @successUpload="successUpload"
            ></upload-image>
            <div class="imgDescribe">
              PC图片长宽比为1920*360，小程序为750*316，大小不超过5MB，支持jpg、png图片格式
            </div>
          </el-form-item>
          <el-form-item label="跳转链接：" prop="linkUrl">
            <el-input
              v-model="form.linkUrl"
              placeholder="请输入跳转链接，以http://或https://开头"
              class="input-width"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="序号：" prop="weight">
            <el-input
              v-model="form.weight"
              placeholder="请输入序号"
              oninput="value=value.replace(/[^\d]/g,'')"
              class="input-width"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" @click="submitCollege('form')">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import to from 'await-to'
import { getCollegeBannerType, getCollegeBannerAdd, getCollegeBannerEdit } from '@/api/college'
import UploadImage from '../../../../components/UploadImage.vue'
export default {
  name: 'BannerManage',
  components: {
    UploadImage,
  },
  props: {
    bannerData: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      widthSize: 0,
      heightSize: 0,
      form: {
        name: '',
        imgUrl: '',
        linkUrl: '',
        weight: '',
        markList: [],
        mark: this.$route.query.mark,
        id: this.id,
      },
      bannerType: [],
      rules: {
        name: [
          { required: true, message: '请输入2~18字名称', trigger: 'blur' },
          { min: 2, max: 18, message: '请输入2~18字名称', trigger: 'blur' },
        ],
        imgUrl: [{ required: true, message: '请上传图片', trigger: 'change' }],
        // linkUrl: [{ required: true, message: '请输入跳转链接', trigger: 'blur' }],
        mark: [{ required: true, message: '请选择板块', trigger: 'change' }],
      },
      successW: '',
      successH: '',
      status: 0,
    }
  },
  watch: {
    bannerData(val) {
      if (JSON.stringify(val) !== '{}' && this.id !== '') {
        // this.form = Object.assign({}, val)
        this.form.name = val.name
        this.form.imgUrl = val.imgUrl
        this.form.linkUrl = val.linkUrl
        this.form.weight = val.weight
        this.form.mark = val.markList[0].key
        this.sizeSelect()
      }
    },
  },
  created() {
    this.getCollegeBannerTypeData()
    if (!this.id) {
      this.sizeSelect()
    }
  },
  methods: {
    changeSelect() {
      this.sizeSelect()
    },
    successWH(val) {
      console.log(val)
      this.successW = val.width
      this.successH = val.height
    },
    successUpload(val) {
      this.status = val
    },
    sizeSelect() {
      if (this.form.mark == 9) {
        this.widthSize = 1920
        this.heightSize = 360
      }
      if (this.form.mark == 11) {
        this.widthSize = 750
        this.heightSize = 316
      }
    },
    async getCollegeBannerTypeData() {
      const [res, err] = await to(getCollegeBannerType())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.bannerType = res.data
    },
    cancel() {
      this.$router.push('collegeBanner')
    },
    async submitRequest(params) {
      this.form.markList.push(this.form.mark)
      const [, err] = await to(params(this.form))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: this.id ? '编辑成功' : '添加成功' })
      this.$router.push('collegeBanner')
    },
    async submitCollege(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (this.form.mark == 9) {
            if (this.status == 0) {
              if (this.id !== '' && JSON.stringify(this.bannerData) !== '{}') {
                this.submitRequest(getCollegeBannerEdit)
              } else {
                this.submitRequest(getCollegeBannerAdd)
              }
            } else {
              if (this.successW == 1920 && this.successH == 360) {
                if (this.id !== '' && JSON.stringify(this.bannerData) !== '{}') {
                  this.submitRequest(getCollegeBannerEdit)
                } else {
                  this.submitRequest(getCollegeBannerAdd)
                }
              } else {
                this.$message({ type: 'error', message: '请选择1920*360的图片' })
              }
            }
          }
          if (this.form.mark == 11) {
            if (this.status == 0) {
              if (this.id !== '' && JSON.stringify(this.bannerData) !== '{}') {
                this.submitRequest(getCollegeBannerEdit)
              } else {
                this.submitRequest(getCollegeBannerAdd)
              }
            } else {
              if (this.successW == 750 && this.successH == 316) {
                if (this.id !== '' && JSON.stringify(this.bannerData) !== '{}') {
                  this.submitRequest(getCollegeBannerEdit)
                } else {
                  this.submitRequest(getCollegeBannerAdd)
                }
              } else {
                this.$message({ type: 'error', message: '请选择750*316的图片' })
              }
            }
          }
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .uploader {
  .el-upload {
    height: 120px;
    width: 120px;
  }
}
.imgDescribe {
  font-size: 12px;
  font-weight: 400;
  color: #909399;
  line-height: 20px;
}
</style>
